import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Link } from "gatsby"
import AppleShareIcon from "../images/share-apple.png"
import { AnchorLink } from "gatsby-plugin-anchor-links"

const HelpPage = () => (
  <Layout>
    <Seo title="Help" />
    <div className="text-2xl font-black full-width px-10 md:px-20 lg:px-24 xl:px-40 py-10 bg-hctv-navy flex">
      <h2 className="text-white">Knowledge Base</h2>
    </div>
    <div className="full-width bg-hctv-blue text-white px-10 md:px-20 lg:px-24 xl:px-40 py-6">
      <div className="full-width px-10 md:px-20 lg:px-24 xl:px-40 flex gap-6 overflow-x-auto font-black text-md hide-scroll flex-nowrap whitespace-nowrap flex-row flex-shrink-0">
        <AnchorLink to="#overview" activeClassName="active-white">
          Overview
        </AnchorLink>
        <AnchorLink to="#ios" activeClassName="active-white">
          iOS
        </AnchorLink>
        <AnchorLink to="#android" activeClassName="active-white">
          Android
        </AnchorLink>
        <AnchorLink to="#" activeClassName="active-white">
          Windows
        </AnchorLink>
        <AnchorLink to="#" activeClassName="active-white">
          MacOS
        </AnchorLink>
      </div>
    </div>
    <div>
      <h1 id="overview" className="font-black text-4xl mt-10">Overview</h1>
      <div className="flex gap-4 my-6">
        <p className="text-gray-800">
          The HCTV app allows users to watch HCTV content on any device with
          ease. You can watch live-streaming content on HCTV’s three live
          channels, view recently streamed meetings, sporting events, shows, and
          other content, and explore and search HCTV’s expansive catalog of past
          and programs.
        </p>
      </div>
    </div>
    <div>
      <h1 className="font-black text-4xl my-10">Downloading</h1>
      <div>
        <div id="ios" className="flex flex-wrap md:flex-nowrap items-baseline bg-hctv-navy text-white px-10 py-4 rounded-t-md">
          <h2 className="font-black text-2xl mt-2 mr-1">iOS</h2>
          <p>(iPhones, iPads, and iPods)</p>
        </div>
        <div className="mb-10 p-10 flex gap-10 justify-center items-center flex-wrap md:flex-nowrap bg-gray-100 rounded-b-md">
          <div>
            <iframe
              className="iphone"
              src="https://www.youtube.com/embed/es8VTjR5NEg?rel=0"
              frameborder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowfullscreen
            />
          </div>
          <div>
            <ol>
              <li className="flex items-start mb-4">
                <span className="text-md mr-4 circle-number text-white rounded-full bg-hctv-green">
                  1
                </span>
                <span className="list-padding">
                  Launch Safari on your ‌iPhone‌ or ‌iPad‌
                </span>
              </li>
              <li className="flex items-start my-4">
                <span className="text-md mr-4 circle-number text-white rounded-full bg-hctv-green">
                  2
                </span>
                <span className="list-padding">
                  Navigate to
                  <a className="ml-1" href="https://app.hudsoncommunity.tv">
                    app.hudsoncommunity.tv
                  </a>
                </span>
              </li>
              <li className="flex items-start my-4">
                <span className="text-md mr-4 circle-number text-white rounded-full bg-hctv-green">
                  3
                </span>
                <span className="list-padding flex items-center gap-1">
                  Tap the Share icon at the bottom of the screen{" "}
                  <img className="w-8" src={AppleShareIcon} />
                </span>
              </li>
              <li className="flex items-start my-4">
                <span className="text-md mr-4 circle-number text-white rounded-full bg-hctv-green">
                  4
                </span>
                <span className="list-padding">
                  Scroll down to the list of actions and tap Add to Home Screen.
                  (If you don't see the action, scroll to the bottom and tap
                  Edit Actions, then tap Add next to the Add to Home Screen
                  action. After that, you'll be able to select it from the Share
                  Sheet.)
                </span>
              </li>
              <li className="flex items-start my-4">
                <span className="text-md mr-4 circle-number text-white rounded-full bg-hctv-green">
                  5
                </span>
                <span className="list-padding">
                  Tap Add in the top-right corner of the screen
                </span>
              </li>
            </ol>
            <p>
              Your new "web app" will appear in the next available space on your
              device's Home screen. To move it like you would any other app, tap
              and hold its icon, then tap Edit Home Screen, and you'll be able
              to drag it to your desired location. When you've placed it where
              you want it, tap Done.
            </p>
          </div>
        </div>
        <div id="android" className="flex flex-wrap md:flex-nowrap items-baseline bg-hctv-navy text-white px-10 py-4 rounded-t-md">
          <h2 className="font-black text-2xl mt-2 mr-1">Android</h2>
          <p>(Samsung Galaxy, Google Pixel, OnePlus, ect.)</p>
        </div>
        <div className="mb-10 p-10 flex gap-10 justify-center items-center flex-wrap md:flex-nowrap bg-gray-100 rounded-b-md">
          {/* <div>
            <iframe
              className="iphone"
              src="https://www.youtube.com/embed/es8VTjR5NEg?rel=0"
              frameborder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowfullscreen
            />
          </div> */}
          <div>
            <ol>
              <li className="flex items-start mb-4">
                <span className="text-md mr-4 circle-number text-white rounded-full bg-hctv-green">
                  1
                </span>
                <span className="list-padding">
                  Open the Google Chrome web browser using your Android device‌
                </span>
              </li>
              <li className="flex items-start my-4">
                <span className="text-md mr-4 circle-number text-white rounded-full bg-hctv-green">
                  2
                </span>
                <span className="list-padding">
                  Navigate to
                  <a className="ml-1" href="https://app.hudsoncommunity.tv">
                    app.hudsoncommunity.tv
                  </a>
                </span>
              </li>
              <li className="flex items-start my-4">
                <span className="text-md mr-4 circle-number text-white rounded-full bg-hctv-green">
                  3
                </span>
                <span className="list-padding">
                  Chrome may present a prompt at the bottom of the screen asking
                  if you would like to add the app to your home screen. If this
                  prompt appears, confirm and follow the steps
                </span>
              </li>
              <li className="flex items-start my-4">
                <span className="text-md mr-4 circle-number text-white rounded-full bg-hctv-green">
                  4
                </span>
                <span className="list-padding">
                  If the prompt does not appear, click the three-dot button in
                  the top right corner of the browser window.
                </span>
              </li>
              <li className="flex items-start my-4">
                <span className="text-md mr-4 circle-number text-white rounded-full bg-hctv-green">
                  5
                </span>
                <span className="list-padding">
                  After opening the options panel, select the option to “Install
                  App”
                </span>
              </li>
              <li className="flex items-start my-4">
                <span className="text-md mr-4 circle-number text-white rounded-full bg-hctv-green">
                  6
                </span>
                <span className="list-padding">
                  A dialog box will appear asking you to confirm the choice.
                  Select the confirmation option.
                </span>
              </li>
              <li className="flex items-start my-4">
                <span className="text-md mr-4 circle-number text-white rounded-full bg-hctv-green">
                  7
                </span>
                <span className="list-padding">
                  Chrome will then install the HCTV icon on your device.
                </span>
              </li>
            </ol>
            <p>
              Your new "web app" will appear in the next available space on your
              device's Home screen. To move it like you would any other app, tap
              and hold its icon, then tap Edit Home Screen, and you'll be able
              to drag it to your desired location. When you've placed it where
              you want it, tap Done.
            </p>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default HelpPage
